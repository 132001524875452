.pagination {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination > ul {
  margin-bottom: 0;
}

.paginationSummary {
  position: absolute;
  right: 0;
}

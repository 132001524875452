@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.modalContainer {
  background-color: var(--white);
  max-height: calc(100% - 260px);
  position: absolute;
  top: 8px;
  border-radius: 8px;
  width: 100%;
}

@media (min-width: 529px) {
  .modalContainer {
    max-height: calc(100% - 200px);
  }
}


// False positive lint error
/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: $size-breakpoint-tablet) {
  .modalContainer {
    width: 70%;
  }
}

// False positive lint error
/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: $size-breakpoint-desktop) {
  .modalContainer {
    width: 50%;
  }
}

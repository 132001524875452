@import '../../styles/functions/zIndexManager';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: z-index-manager('overlayMask');
  background-color: var(--black);
}


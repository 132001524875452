@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';
@import '~@palmetto/palmetto-design-tokens/build/scss/variables-color';

.timeline {
  margin-top: $size-spacing-sm;
  padding: $size-spacing-sm;
  background: $color-brand-white-base;
  border-radius: $size-border-radius-sm;
}

.heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: $size-spacing-sm;
  font-size: $size-font-xs;
}

.headingText {
  font-weight: bold;
}

.item {
  display: flex;
  justify-content: space-between;
}

.icon {
  font-size: $size-font-sm;
  color: $color-brand-grey-400;
  cursor: pointer;

  &:hover {
    color: $color-brand-grey-600;
  }
}

.popover {
  padding: $size-spacing-sm;
  color: $color-brand-grey-50;
  max-width: $size-width-3xl;
}

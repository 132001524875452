.header {
  background-color: var(--white);

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 20px;
  }

  .logoContainer {
    min-height: 33px;
    font-size: 18px;
  }

  .userContainer {
    align-self: self-end;
    text-align: right;

    .userProfileIcon {
      margin-right: 3px;
      margin-left: 5px;
      width: 32px;
    }

    .homeOwnerNav:hover {
      cursor: default;
    }
  }
}



.h1 {
  display: inline-block;
  margin: 10px 0;
  font-size: 24px;
}

.arrowLeft {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  color: #2392ec;
  font-size: 24px;
}

.headerTitleWrapper {
  display: flex;
  margin: 0;
  padding: 25px 25px 25px 0;
  vertical-align: middle;
}

.whichBestDescribesYouMsg {
  font-size: 14px;
  font-weight: 700;
}

.signUpTypeSelector {
  display: flex;
  justify-content: space-around;

  .memberTypeBtn {
    display: flex;
    justify-content: center;
    border: 0;
    border-radius: 8px;
    background: var(--white);
    padding: 16px;
    width: 160px;

    .btnContent {
      .label {
        margin-top: 8px;
        font-weight: 700;
      }

      .description {
        color: var(--greyish-green);
        font-size: 14px;
      }
    }
  }

  .btnSelected {
    outline: none;
    border: 0;
    background: var(--light-sea-foam-green);

    &:focus {
      outline: none;
    }
  }
}

.buttonMargin {
  margin-top: 20px;
}

.signUpSelectCountry {
  &:hover {
    background-color: var(--color-brand-secondary-50);
    transition: background-color 0.2s ease;
    transition: border-color 0.2s ease;
    cursor: pointer;
  }

  &.selected {
    background-color: var(--color-brand-grey-50);
  }
}

.timeBlockSelectorContainer {
  height: auto !important;

  .timeBlockRow {
    margin: 0 auto;

    .timeBlock {
      flex-grow: 1;
      margin: 5px;
      padding: 0;
      font-size: 18px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      button {
        border: 2px solid var(--darker-grey);
        border-radius: 5px;
        background: white;
        padding: 10px 20px;
        width: 100%;
        height: 100%;
        text-align: center;

        &:not(:disabled):hover {
          border-color: var(--azure);
        }
      }

      .selected {
        background-color: var(--azure);
        color: white;
        font-weight: bold;

        &:hover {
          background-color: var(--azure);
        }
      }
    }
  }
}

.container {
  text-align: center;
}

.errorIcon {
  margin-right: 15px;
  vertical-align: middle;
  text-align: center;
  color: var(--error);
  font-size: 33px;
}

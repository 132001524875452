.columnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 170px;
  white-space: nowrap;
}

.deleteButton {
  /* Appointment delete button hidden temporarily. See note in AppointmentDate component. */
  display: none;
  border: none;
  background: transparent;
}

.deleteButton > svg {
  fill: var(--azure);
  width: 100%;
  height: 100%;
}

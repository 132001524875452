:global .modalDialog {
  max-width: 800px;
}

:global .modalBackdrop {
  background: var(--white);
}

:global .modalContent {
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  color: var(--very-dark-grey);
  font-size: 18px;
}

:global .modalBody {
  padding: 50px 75px;
}

.titleText {
  margin: 25px;
}

.titleIcon {
  font-size: 40px;
}

.dangerousHtml {
  font-size: 14px;
}

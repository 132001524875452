.pageHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--color-brand-grey-100);
  padding: 51px 0 20px;
  width: 100%;
  text-align: left;

  .heading {
    font-family: var(--asset-fonts-brand);
    font-weight: 900;
    font-size: 1.25rem;

    .pageHeaderIcon {
      margin-right: 10px;
    }
  }
}

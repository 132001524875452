.loadingIndicator {
  padding: 1rem 0;
}

.linkExpiredContainer,
.confirmationContainer {
  text-align: center;

  .checkCircleIcon,
  .exclamationTriangleIcon {
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
  }

  .heading {
    margin-bottom: 1rem;
    font-family: var(--asset-fonts-body);
    font-size: 1.25rem;
    font-weight: bold;
  }

  .subHeading {
    font-size: 1.13rem;
  }
}

.linkExpiredContainer {
  .exclamationTriangleIcon {
    color: var(--warning);
  }
}


.confirmationContainer {
  .checkCircleIcon {
    display: inline-block;
    color: var(--light-palmetto-green);
  }
}

.appointmentSchedulerContainer {
  display: flex;
  justify-content: center;

  .appointmentCalendar {
    align-self: flex-start;
  }

  .appointmentScheduler {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 2rem;
    border: 1px solid var(--light-blue-grey);
    text-align: center;

    button {
      outline: none;
      border: 0;
    }

    .appointmentHeader {
      border-bottom: 1px solid var(--light-blue-grey);
      padding: 1rem;

      .questionHeader {
        font-family: var(--asset-fonts-body);
        font-size: 18px;
      }

      .instructions {
        font-size: 1rem;
      }
    }

    .appointmentForm {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;

      .scheduleAppointmentButton {
        display: block;
        background: var(--palmetto-green);
        padding: 15px 0;
        width: 100%;
        color: var(--white);
        font-family: var(--asset-fonts-body);
        font-weight: 500;

        &:disabled {
          background: var(--very-light-palmetto-green);
        }
      }
    }
  }
}




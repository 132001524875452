.filterButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  background: transparent;
  color: var(--azure);

  > svg {
    margin: 0.5rem;
  }
}

.closeButton {
  border: 0;
  background: transparent;
  padding: 0;
  min-width: 2rem;
  min-height: 2rem;

  > svg {
    fill: var(--azure);
    width: 2rem;
    height: 2rem;
  }
}

.filterSelect {
  min-width: 7rem;
}

.narrowSelect {
  min-width: 8rem;
}

.wideSelect {
  min-width: 14rem;
}

.opportunitiesReportControls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .exportToCsvButton {
    margin-left: var(--size-spacing-lg);
    width: max-content;
  }
}

.opportunitiesReportControls:first-of-type {
  margin-bottom: 15px;
}

.searchBox {
  flex: 1;
  width: 20rem;
}

h3 {
  font-family: var(--asset-fonts-brand);
  font-size: 1.17em;
}

/* reboot was messing with this and conflicting with the design system */
svg {
  vertical-align: unset;
}

/* Aligns Caret for Tall Accordion Panels */
.centerCaret {
  > div {
    align-items: center;
  }
}

.signupVerifyStyles {
  display: flex;
  flex-direction: column;

  * {
    font-size: 16px;
    font-style: normal;
  }

  div {
    margin: 10px auto;
  }

  img {
    margin-left: 27px;
  }

  p {
    text-align: center;
    color: var(--light-dark-grey);
  }

  label {
    display: none;
  }

  input {
    border-radius: 5px;
  }

  button {
    background-color: var(--dark-green);
    color: var(--white);
    font-size: 16px;
    font-style: normal;
  }

  .sectionHeader {
    margin-bottom: 0;
    font-size: 20px;
  }

  .verifyAlert {
    width: 100%;
    text-align: center;
  }

  .resendButton {
    border: none;
    border-bottom: 1px solid var(--dark-green);
    background: transparent;
    padding: 0;
    color: var(--dark-green);
    font-size: 12px;
    font-style: underline;
  }

  .updatePhone {
    margin-right: 5px;
    border: none;
    border-bottom: 1px solid var(--dark-green);
    background: transparent;
    padding: 0;
    color: var(--dark-green);
    font-size: 12px;
    font-style: underline;
  }
}

.verificationCodeInput {
  display: flex;
  flex-direction: column;

  input {
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
    max-width: 200px;
    letter-spacing: 0.3rem;
  }
}

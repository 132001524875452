/* stylelint-disable */
@import '@palmetto/palmetto-design-tokens/build/scss/variables-size.scss';

.fulfillment-tabs {
  a,
  a:visited {
    color: var(--color-brand-grey-400);
    transition-property: border-color, color;
    transition-duration: 0.2s;
    text-decoration: none;
    border-radius: var(--size-spacing-xs);
    padding: var(--size-spacing-sm);

    &:hover {
      color: var(--color-brand-grey-darker);
      text-decoration: none;
    }

    &.active {
      color: var(--color-brand-grey-darker);
      background-color: var(--color-brand-white-500);
      text-decoration: none;
    }
  }
}

.fulfillment-tabs-mobile {
  a,
  a:visited {
    color: var(--color-brand-grey-600);
    transition-duration: 0.2s;
    text-decoration: none;
    padding: var(--size-spacing-sm) var(--size-spacing-lg);
    border-bottom: solid var(--color-brand-grey-lighter) 1px;

    &:hover {
      background-color: var(--color-brand-grey-50);
      text-decoration: none;
    }

    &.active {
      color: var(--color-brand-grey-600);
      text-decoration: none;
    }
  }
}

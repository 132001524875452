/* stylelint-disable selector-class-pattern */

// Increase specificity
.readOnly.readOnly.readOnly {
  cursor: default;

  :global {
    /* stylelint-disable-next-line selector-max-id */
    #startingMonthLabel {
      color: var(--color-brand-grey-600);
    }

    .react-select__control {
      padding: 0;
      border: 0;
      align-items: baseline;
      box-shadow: none;

      .react-select__value-container {
        align-items: center;
        justify-content: flex-end;
      }

      .react-select__indicators {
        display: none;
      }

      label {
        color: var(--color-brand-grey-600);
      }
    }
  }

  div,
  input {
    cursor: default;
    color: var(--color-brand-grey-600);
    -webkit-text-fill-color: var(--color-brand-grey-600);
  }
}

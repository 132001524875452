.columnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 170px;
  white-space: nowrap;
}

.deleteButton {
  width: 44px;
  border: none;
  background: transparent;
}

.formContainer {
  text-align: initial;
}

.label {
  display: block;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  color: #3c453e;
  font-size: 0.875rem;
  font-weight: 700;
}

.textarea {
  box-sizing: border-box;
  transition-duration: 300ms;
  transition-property: border, background-color;
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);
  border: 1px solid #d5dcd7;
  border-radius: 8px;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 0.75rem;
  width: 100%;
  color: #3c453e;

  &:focus {
    outline: none;
  }
}

.button {
  background-color: var(--azure) !important;
  padding: 10px !important;

  &:hover {
    background-color: var(--azure) !important;
  }
}

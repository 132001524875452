@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

// Design tokens were used here, and have !important applied to them so that
// this class overrides classes being used in palmetto-component's Modal.
// An attempt was made to pass util classes directly to the Modal, however,
// their specificity was overridden. This is meant to be a temporary solution.
@media (min-width: $size-breakpoint-tablet) {
  .fullWidthTablet {
    width: var(--size-width-100) !important;
  }
}

@media (min-width: $size-breakpoint-desktop) {
  .fullWidthTablet {
    width: var(--size-width-70) !important;
  }
}

@media (min-width: $size-breakpoint-hd) {
  .fullWidthTablet {
    width: var(--size-width-50) !important;
  }
}

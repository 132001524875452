.autoCompleteRoot {
  position: relative;
}

.autoCompleteDropdownContainer {
  position: absolute;
  top: calc(80px);
  z-index: 10;
  border: 1px solid var(--color-brand-grey-lighter);
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--color-brand-white-base);
  padding: var(--size-spacing-md) 0;
  width: 100%;
}

.autoCompleteSuggestion {
  cursor: pointer;
  padding: 10px 15px;
  color: var(--color-brand-dark-base);
}

.autoCompleteSuggestionActive {
  background-color: var(--color-brand-secondary-lightest);
  cursor: pointer;
  padding: 10px 15px;
  color: var(--color-brand-dark-base);
}

.autoCompleteSpinnerContainer {
  display: flex;
  justify-content: center;
  border: 0;
  box-shadow: none;
  color: var(--color-brand-dark-base);
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .beforeLabel {
    margin-right: 8px;
  }

  .afterLabel {
    margin-left: 8px;
  }

  .dropdownButton {
    > button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--light-blue-grey);
      width: 100%;
    }

    > div {
      max-height: 175px;
      overflow: scroll;
    }
  }
}

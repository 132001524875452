@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.documentSelectedCheckbox {
  top: 8px;
  right: 0;
}

.galleryImageContainer {
  text-transform: uppercase;
}

.galleryImage {
  object-fit: cover;
}

.documentFileName {
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

@media (min-width: $size-breakpoint-tablet) {
  .documentFileName {
    -webkit-line-clamp: 3;
    word-break: break-word;
  }
}

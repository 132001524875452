.leftColumn {
  border-right: 1px solid #c8c8c8;

}

.card-header {
  font-size: 10px;
}

.formTitle {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.formGroupTitle {
  margin: 5px 0 15px;
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}

.h3 {
  margin: 10px 0 15px;
  font-size: 16px;
  font-weight: 600;
}

.formCheckbox {
  margin: 20px 0;
}

.formCheckbox label {
  margin-left: 10px;
}


.formFooter {
  padding: 1.25rem;
}

.formFooter button {
  margin-right: 25px;
}

@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.optionTileGroup {
  [class*='OptionTileGroup-module_option'] {
    justify-content: center;
    margin-right: 0;

    @media (min-width: $size-breakpoint-tablet) {
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;
      }
    }
  }
}

.coBorrowerFormContainer {
  margin-bottom: 2rem;
}

.leftColumn {
  border-right: 1px solid #c8c8c8;
}

.formTitle {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.formTitleContainer {
  border-bottom: 1px solid #c8c8c8;
}

.formGroupTitle {
  margin: 5px 0 15px;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}

.h3 {
  margin: 10px 0 15px;
  font-size: 16px;
  font-weight: 600;
}

.formCheckbox {
  margin: 20px 0;

  div {
    display: inline;
  }

  label {
    display: inline;
    margin-left: 10px;
  }
}

.underlinedLabel {
  text-decoration: underline;
}

.formFooter {
  padding: 1.25rem;
}

.formFooter button {
  margin-right: 25px;
}

.borrowerDivider {
  margin: 2rem 0;
}

.disclaimer {
  font-size: 0.8rem;
}

.esignButtonLink {
  border: none;
  background: transparent;
  padding: 0;
  color: var(--azure);
}

.esignButtonLink:hover {
  text-decoration: underline;
  color: #0f6ab3;
}

@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size.scss';

.container {
  color: var(--color-brand-grey-dark);

  .alertContainer {
    margin-bottom: var(--size-spacing-lg);
  }

  .header {
    cursor: default;
    margin-bottom: 1rem;
    text-align: center;
    line-height: var(--size-spacing-lg);
    font-size: var(--size-font-lg);
  }

  .subHeader {
    display: block;
    text-align: center;
    text-decoration-line: underline;
    line-height: var(--size-spacing-lg);
    font-size: var(--size-font-xs);

    svg {
      margin-right: var(--size-spacing-2xs);
    }
  }

  .serviceAgreementBox {
    margin: var(--size-spacing-lg) 0;
    border: 1px solid var(--color-brand-grey-lighter);
    border-radius: var(--size-border-radius-md);
    background: var(--color-brand-grey-lightest);
    cursor: default;
    padding: var(--size-spacing-sm);
    max-height: var(--size-height-2xl);
    overflow-y: scroll;

    h1 {
      text-align: center;
      font-size: var(--size-font-md);
      font-weight: bold;
    }

    h2 {
      text-align: center;
      font-size: var(--size-font-sm);
      font-weight: bold;
    }

    h3 {
      text-align: center;
      font-size: var(--size-font-xs);
      font-weight: bold;
    }

    p {
      font-size: var(--size-font-xs);
    }

    table {
      margin-bottom: var(--size-spacing-md);
      border: 1px solid var(--color-brand-black-base);
      width: var(--size-width-100);
      text-align: center;
      white-space: normal;
      font-size: var(--size-font-xs);
    }

    tr {
      border-bottom: 1px solid var(--color-brand-black-base);
    }

    td {
      border-right: 1px solid var(--color-brand-black-base);
    }
  }

  .checkbox {
    margin-bottom: var(--size-spacing-lg);
  }

  @media (min-width: $size-breakpoint-tablet) {
    .serviceAgreementBox {
      max-height: var(--size-height-3xl);
    }
  }
}

.printFriendly {
  @page {
    margin: 2cm;
  }

  h1,
  h2 {
    text-align: center;
  }
}

// We need to target Bootstrap's container class here
.container {
  padding-right: var(--size-spacing-lg) !important;
  padding-left: var(--size-spacing-lg) !important;
  width: 100%;
  max-width: 1248px !important;

  // Partially override v1's container styles to support new palmetto-components/ux designs on desktop and mobile
  &.noPadding {
    padding: 0 !important;
  }
}




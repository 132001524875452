
.opportunityDetailsWrapper {
  flex-basis: 250px;
  margin-right: 2rem;
  background-color: var(--very-light-grey);
  padding: 1rem;

  .opportunityDetailsTitle,
  .detailsField {
    margin-bottom: 1rem;
  }

  .opportunityDetailsTitle,
  .detailsFieldLabel {
    font-weight: bold;
  }

  .detailsFieldLabel {
    margin-bottom: 0.25rem;
    font-size: 0.85rem;
  }

  .detailsField:last-child {
    margin-bottom: 0;
  }
}


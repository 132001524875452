@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.appliedFilter {
  .appliedFilterBtn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    @media (min-width: $size-breakpoint-tablet) {
      border-top-right-radius: var(--size-border-radius-sm) !important;
      border-bottom-right-radius: var(--size-border-radius-sm) !important;
    }
  }

  .appliedFilterRemove {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    @media (min-width: $size-breakpoint-tablet) {
      position: absolute !important;
      right: 0;
      visibility: hidden;
      border-top-left-radius: initial;
      border-bottom-left-radius: initial;
    }
  }

  // stylelint-disable-next-line order/order
  &:focus-within,
  &:hover {
    .appliedFilterRemove {
      visibility: visible;
    }
  }
}

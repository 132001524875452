.container {
  font-family: var(--asset-fonts-body);

  a {
    color: var(--color-brand-primary-base);

    &:hover {
      color: var(--color-brand-primary-base);
    }
  }
}

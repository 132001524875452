@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.errorMessage,
.loadingIndicator,
.noAppointmentsMessage {
  padding: 1rem 0;
  font-family: var(--asset-fonts-body);
}

.timeBlockSelectorContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .availabilityBlocks {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0.35rem;
    width: 100%;

    .timeBlockItem {
      display: flex;
      flex: 0 0 31.33%;
      margin: 1%;

      .timeBlockButton {
        border: 1px solid var(--light-blue-grey);
        border-radius: 5px;
        background: var(--white);
        padding: 1rem 0.5rem;
        width: 100%;
        color: var(--very-dark-grey);
        font-family: var(--asset-fonts-body);

        &.selected {
          border: 1px solid var(--azure);
          background: var(--azure);
          color: var(--white);
        }

        /* stylelint-disable selector-max-compound-selectors */
        .buttonText {
          margin: 0 auto;
        }
      }
    }

    @media (min-width: $size-breakpoint-desktop) {
      .timeBlockItem {
        flex: 0 0 18%;
      }
    }
  }

  .errorMessage {
    padding-top: 0;
    color: var(--error);
  }
}


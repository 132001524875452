.breadcrumbContainer {
  display: flex;
  align-items: center;
  margin: 25px 0;
  border-bottom: 1px solid  var(--darker-grey);
  padding-bottom: 25px;
  font-size: 18px;
}

.breadcrumbUrl {
  color: var(--azure);
}

.chevronContainer {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.breadcrumbChevron {
  display: block;
  margin: 0 10px;
  font-size: 14px;
}

.loanAmountContainer {
  display: flex;
  border: 1px solid var(--darker-grey);
  padding: 10px;
  width: 222px;

  .loanIcon {
    margin-right: 10px;
    width: 48px;
    height: 48px;
  }

  .loanAmountTitle,
  .loanAmountNumber {
    margin: 0;
    padding: 0;
  }

  .loanAmountTitle {
    font-size: 13px;
  }

  .loanAmountNumber {
    font-family: var(--asset-fonts-body);
    font-size: 24px;
    font-weight: 600;
  }
}

.financeOptionsContainer {
  margin: 25px 0 0;
  border: 1px solid var(--darker-grey);
  padding: 32px;

  .financeOptionsTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    border-bottom: 1px solid var(--darker-grey);
    padding-bottom: 30px;
  }

  .financeOptionsTitle {
    margin-bottom: 0;
    font-family: var(--asset-fonts-body);
    font-size: 18px;
    font-weight: 600;
  }

  .financeOptions {
    margin: 0;
    margin-top: 40px;
    padding: 0;
    list-style-type: none;

    &.financeOptionsLoading {
      margin-top: 20px;
    }
  }

  .financeOption {
    position: relative;
    margin-bottom: 20px;
    border: 1px solid var(--darker-grey);
    padding: 15px 0;

    &:last-child {
      margin-bottom: 0;
    }

    .status {
      display: flex;
      display: none;
      position: absolute;
      top: 5px;
      align-items: center;
      padding: 2px 30px 2px 8px;
      text-transform: uppercase;
      color: var(--white);
      font-family: var(--asset-fonts-body);
      font-size: 10px;
      clip-path: polygon(0% 0%, 100% 0%, 92% 50%, 100% 100%, 0% 100%);
    }

    .disclaimer {
      position: absolute;
      bottom: -7px;
      align-items: center;

      button {
        font-family: var(--asset-fonts-body);
        font-size: 14px;
      }
    }

    &.preApproved,
    &.preQualified {
      padding-top: 37px;

      .status {
        display: flex;
      }

      .checkMarkCircle {
        margin-right: 5px;
        width: 11px;
      }
    }

    &.preApproved {
      border: 2px solid var(--dark-sea-foam-green);

      .status {
        background-color: var(--dark-sea-foam-green);

        &::after {
          content: 'Pre Approved';
        }
      }
    }

    &.preQualified {
      .status {
        background-color: var(--cornflower-blue);

        &::after {
          content: 'Pre Qualified';
        }
      }
    }

    .offer {
      display: flex;
      align-items: center;

      > span {
        display: inline-block;
        width: 20%;
      }
    }

    .value {
      color: var(--very-dark-grey);
      font-family: var(--asset-fonts-body);
      font-size: 21px;
      font-weight: 600;
    }

    .description {
      color: var(--even-darker-grey);
    }

    .financeOptionLogo {
      display: flex;
      align-items: center;
      margin-left: 24px;
    }

    .financeOptionImg {
      width: 100%;
      max-width: 112px;
    }

    .continueButton {
      border: 0;
      border-radius: 4px;
      background-color: var(--sea-foam-green);
      padding: 6px 12px;
      color: var(--white);

      &:hover {
        background-color: var(--dark-sea-foam-green);
        text-decoration: none;
      }
    }
  }
}

.additionalInfo {
  margin: 20px 0 0;
  font-size: 14px;

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.equalOpportunityLogo {
  display: inherit;
  margin-top: 10px;
  margin-left: auto;
  width: 66px;
}

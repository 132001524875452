.naked {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.contactProgressHeaderInfo {
  margin-top: 0;
  margin-bottom: 0;
}

.progressCircleIcon {
  vertical-align: middle;
}

.notesListContainer {
  padding: 0;
}

$border-color: rgb(212, 212, 212);

.filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 90%;
}

.cleanFilter {
  cursor: pointer;
}

.ahj {
  h3 {
    font-weight: 800;
  }

  details {
    border-radius: var(--size-border-radius-sm);
    box-shadow: var(--size-box-shadow-xs);
  }
}

.select {
  flex-basis: calc(50% - 40px);
  padding: 1rem;
}

.expand,
.collapse {
  cursor: pointer;
}

@media print {
  .collapseBox {
    display: none !important;
  }
}

@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.mainAppContainer {
  height: 100%;
  overflow: auto;

  .columnContainer {
    display: flex;
    flex-direction: column;
    padding-top: 55px;
    width: 100%;
    height: 100%;

    .nonFooterContainer {
      flex: 1 0 auto;
    }

    .footer {
      flex-shrink: 0;
    }
  }

  &.v2Container {
    background-color: var(--color-brand-grey-lightest);
  }

  &.fullScreen {
    display: flex;
    height: 100%;
  }
}

@media (min-width: $size-breakpoint-desktop) {
  .mainAppContainer {
    .columnContainer {
      padding-top: 0;
    }

    &.sideNavOffset {
      margin-left: 80px;
    }
  }
}

@media print {
  * {
    position: unset !important;
    overflow: unset !important;
  }

  .mainAppContainer {
    nav {
      display: none;
    }
  }
}

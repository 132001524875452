.linkified {
  overflow: hidden;

  a {
    word-break: break-all;
  }

  > * + * {
    margin-top: var(--size-spacing-sm);
  }
}

@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';
@import '../../styles/functions/zIndexManager';

.hamburgerNav,
.sideNavContainer {
  position: fixed;
}

.hamburgerNavBackground {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index-manager('hamburgerNavBackground');
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  width: 100%;
  height: 55px;
}

.hamburgerNav {
  display: block;
  top: 10px;
  left: 15px;
  flex-shrink: 0;
  z-index: z-index-manager('hamburgerNav');
  padding: 8px;
  width: 44px;
}

.sideNavContainer {
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  transition: 0.5s;
  z-index: z-index-manager('overlay');
  border-right: 1px solid var(--darker-grey);
  background-color: var(--white);
  padding-top: 50px;
  width: 0;
  overflow-x: hidden;
  overflow-y: hidden;

  &.expanded {
    width: 280px;
  }

  a:focus,
  button:focus {
    outline: none;
  }

  .navListItem,
  .navItemLink,
  .navItemButton,
  .logoutButton {
    white-space: nowrap;
  }

  .navItemLink,
  .navItemButton,
  .logoutButton {
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .navItemLink,
  .navItemButton {
    padding: 6px 0;
  }

  .subMenu {
    ul {
      margin-left: 0;
      padding-left: 20px;
      list-style-type: disc;
    }

    .navItemLink {
      padding: 0;
      color: var(--dark-grey);

      &:hover {
        text-decoration: none;
      }
    }

    &.isExpanded {
      display: initial;
    }
  }

  .navList,
  .logoutButton {
    margin: 2px;
    padding-left: 0;
    text-decoration: none;
  }

  .navItemContainer {
    flex: 1 1 auto;
    overflow: auto;
  }

  .navItemIcon {
    display: inline-block;
    width: 77px;
    text-align: center;
  }

  .navItemTitle {
    display: inline-block;
    width: 190px;
    text-decoration: none;
    color: var(--dark-grey);
  }

  .logoutButton,
  .navItemButton {
    flex-shrink: 0;
    border: 0;
    background-color: initial;
    text-align: initial;
  }

  .navItemButtonIcon {
    margin-left: 0.5rem;
    color: var(--even-darker-grey);
  }

  .logoutButton {
    border-top: 1px solid var(--light-grey);
    padding: 12px 0;
  }
}

@media (min-width: $size-breakpoint-desktop) {
  .hamburgerNavBackground {
    display: none;
  }

  .hamburgerNav {
    top: 0;
    left: 24px;
    transition: 0.5s;
    width: 33px;
    height: 28px;

    &.expanded {
      left: 224px;
    }
  }

  .sideNavContainer {
    padding-top: 37px;
    width: 80px;

    .navItemLink,
    .navItemButton {
      padding: 10px 0;
    }
  }
}

@media print {
  .hamburgerNavBackground,
  .hamburgerNav {
    display: none;
  }
}

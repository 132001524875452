.topArea {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.bottomArea {
  display: flex;
  justify-content: center;
  background-color: var(--light-grey);
  padding: 1rem;
}

.zipcodeRow {
  margin: 15px;
}

.areaContents {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.zipcodeBox {
  width: 100%;
  max-width: 40rem;
}

.zipcodeErrorMsg {
  color: var(--error);
  font-size: 1rem;
}

.loadingIndicator {
  margin-top: 2rem;
}

.timeBlockSelectorHeading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 10px;

  .selectedDate {
    font-size: 16px;
    font-weight: 600;
  }
}

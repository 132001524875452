@import '@palmetto/palmetto-design-tokens/build/scss/variables-size';

.alchemy {
  [data-reach-tab-list] {
    border-bottom: 1px solid var(--color-brand-grey-100);
    background-color: transparent;
    padding: 0 var(--size-spacing-lg);
    width: auto;
    overflow: auto;

    @media (min-width: $size-breakpoint-tablet) {
      padding: 0 var(--size-spacing-3xl);
    }

    @media (min-width: $size-breakpoint-desktop) {
      padding: 0;
    }
  }

  [data-reach-tab] {
    transition: all 0.2s;
    margin-right: var(--size-spacing-lg);
    border-bottom-width: 3px;
    background-color: transparent;
    padding: var(--size-spacing-md) 0;
    white-space: nowrap;
    color: var(--color-brand-grey-500);
    font-weight: var(--size-font-weight-bold);

    /* define regular focus styles for non-supporting browsers */
    &:focus {
      outline: 3px solid var(--color-brand-primary-500);
    }

    /* Show focus styles on keyboard focus. */
    &:focus-visible {
      outline: 3px solid var(--color-brand-primary-500);
    }

    /* Hide focus styles if they're not needed, for example,
    when an element receives focus via the mouse. */
    &:focus:not(:focus-visible) {
      outline: 0;
    }

    @media (min-width: $size-breakpoint-tablet) {
      margin-right: var(--size-spacing-lg);
    }
  }

  [data-reach-tab][data-selected] {
    border-bottom-color: var(--color-brand-primary-500);
    background-color: transparent;
    color: var(--color-brand-primary-600);
  }

  [data-reach-tab-panel] {
    /* define regular focus styles for non-supporting browsers */
    &:focus {
      outline: 3px solid var(--color-brand-primary-500);
    }

    /* Show focus styles on keyboard focus. */
    &:focus-visible {
      outline: 3px solid var(--color-brand-primary-500);
    }

    /* Hide focus styles if they're not needed, for example,
    when an element receives focus via the mouse. */
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }
}

.utilityBillOption {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-brand-grey--600);

  &:hover {
    border-color: var(--color-brand-primary-500);
    text-decoration: none;
  }

  &:focus {
    outline: 3px solid var(--color-brand-primary-500);
  }

  /* Show focus styles on keyboard focus. */
  &:focus-visible {
    outline: 3px solid var(--color-brand-primary-500);
  }

  /* Hide focus styles if they're not needed, for example,
  when an element receives focus via the mouse. */
  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.container {
  margin: 0 auto;
  border: 1px solid var(--darker-grey);
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  background-color: var(--white);
  padding: 20px 20px 0;
}

.instruction {
  font-size: 85%;
}

@media (min-width: $size-breakpoint-desktop) {
  .container {
    width: 100%;
  }
}

.searchBox {
  flex: 1;
  width: 20rem;
}

.leadsControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noWrap {
  white-space: nowrap;
}

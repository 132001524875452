// stylelint-disable

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length; }
  }
}

.mw-100 { max-width: 100%; }
.mh-100 { max-height: 100%; }

// Viewport additional helpers

.min-vw-100 { min-width: 100vw; }
.min-vh-100 { min-height: 100vh; }

.vw-100 { width: 100vw; }
.vh-100 { height: 100vh; }

@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.mainContainer {
  font-family: var(--asset-fonts-body);

  a {
    color: var(--color-brand-primary-base);

    &:hover {
      color: var(--color-brand-primary-base);
    }
  }
}

.commissionPlanHistoryContainer {
  display: grid;
  column-gap: var(--size-spacing-md);
  grid-template-columns: var(--size-width-sm) var(--size-width-2xl);
  grid-template-rows: auto;

  .lineItem {
    grid-column: 1 1;
    justify-self: center;
    align-self: center;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      z-index: 2;
    }
  }

  .contentItem {
    display: block;
    grid-column: 2 2;
    padding: var(--size-spacing-sm) 0;
  }
}

.adderSectionWrapper .adderSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--size-spacing-xs) 0;
  font-size: var(--size-font-md);

  &:not(:last-child) {
    border-bottom: var(--size-border-xs) solid var(--color-brand-grey-200);
  };
}

.step {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    background-color: var(--color-brand-grey-lighter);
    width: 2px;
    content: '';
  }
}

/* Start the line further down on the first list item */
.step:first-child::before {
  top: 50%;
}

/* Stop the line short on the final list item */
.step:last-child::before {
  height: 50%;
}

/* Hide the line if list of one */
.step:first-child:last-child::before {
  height: 0%;
}

.container {
  margin-top: var(--size-spacing-3xl);
  margin-bottom: var(--size-spacing-3xl);
  text-align: center;
}

.icon {
  margin-right: var(--size-spacing-md);
  width: var(--size-width-md);
}

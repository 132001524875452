.pageContentWrapper {
  display: flex;
  align-items: flex-start;
}

.availabilityTableWrapper {
  flex-grow: 1;

  .scheduleAppointmentControls {
    display: flex;

    .calenderPicker {
      margin-right: 32px;
    }
  }
}

.detailsFieldLabel {
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
  font-weight: bold;
}

.detailsFieldValue:last-child {
  margin-bottom: 0;
}

.modalContainer {
  text-align: left;
  font-size: 16px;

  .instructions {
    margin-bottom: 10px;
    color: var(--very-dark-grey);
    font-family: var(--asset-fonts-body);

    .selectText {
      margin-right: 16px;
      font-size: 18px;
    }
  }

  .designNotesTitle,
  .detailsTitle {
    font-family: var(--asset-fonts-body);
    font-size: 14px;
  }

  .designNotes {
    .designNotesTextArea {
      width: 100%;
    }
  }

  .errorContainer {
    margin-bottom: 10px;

    .submitErrorMessage {
      color: var(--error);
      font-family: var(--asset-fonts-body);
    }
  }

  .appointmentDetails {
    display: flex;
    border: solid 1px var(--grey);
    background-color: var(--very-light-grey);
    padding: 16px;

    .detailsAttribute {
      margin-top: 5px;
    }

    .customerDetails,
    .repDetails {
      flex: 1 1 0;
    }

    .repDetails {
      border-left: 1px solid var(--grey);
      padding-left: 16px;
    }
  }
}



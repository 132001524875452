.prompt {
  margin-bottom: 1rem;
}

.footer {
  padding: 0, 1.25rem;
}

.footer button {
  margin-right: 25px;
}

@import '../../styles/functions/zIndexManager';

.dropDownContainer {
  display: inline-block;
  position: relative;
}

.button {
  border: 0;
  background-color: initial;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.arrowUp {
  position: absolute;
  top: -10px;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--light-blue-grey);
  border-left: 10px solid transparent;
  width: 0;
  height: 0;

  &::after {
    position: absolute;
    top: 2px;
    left: -10px;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white);
    border-left: 10px solid transparent;
    width: 0;
    height: 0;
    content: '';
  }
}

.menu {
  position: absolute;
  right: 0;
  z-index: z-index-manager('overlay');
  border: 1px solid var(--light-blue-grey);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 6px 12px var(--light-blue-grey);
  background-color: var(--white);

  .unorderedList {
    margin: 0;
    padding: 5px 0;
    list-style: none;
    text-align: left;
  }

  .listItem {
    white-space: nowrap;

    > a {
      display: block;
      padding: 7px 20px;
      color: inherit;

      &:hover {
        background-color: var(--very-light-grey);
        text-decoration: none;
      }
    }
  }
}

.container {
  text-align: center;
}

.loadingImg {
  width: 2em;
}

.loadingMessage {
  margin-left: 24px;
}

.accordion {
  h3 {
    font-weight: 800;
  }

  details {
    border-radius: var(--size-border-radius-sm);
    box-shadow: var(--size-box-shadow-xs);
  }
}

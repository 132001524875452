/* Edit Button */
.editButton {
  border: 0;
  background: none;
  padding: 0;
  text-align: left;
  color: var(--color-brand-primary-500);
  font-size: var(--size-font-sm);

  &:focus {
    border: none;
  }
}

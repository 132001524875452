.summaryBar {
  position: fixed;
  bottom: 0;
  transform: translateY(100px);
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out 0s;
}

.showSummaryBar {
  transform: translateY(0);
}

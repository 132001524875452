@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.container {
  background-color: var(--color-brand-grey-lightest);
  padding: var(--size-spacing-lg) 0;
  text-align: center;
}

.button {
  margin-left: var(--size-spacing-md);
}

.error {
  color: var(--error);
}

@import '~@palmetto/palmetto-design-tokens/build/scss/variables-size';

.availabilityDateSelectorContainer {
  margin-bottom: 2.3rem;

  .availabilityH2 {
    margin: 10px 0 25px;
    font-size: 18px;
  }

  .dateBlockContainer {
    display: flex;
  }

  .dateBlockRow {
    display: flex;
    flex: 1;
  }

  .dateBlock {
    flex-grow: 1;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    background: var(--white);
    padding: 10px 15px;
    text-align: center;

    &:not(:disabled):hover {
      border: 2px solid var(--azure);
    }
  }

  .dateBlock.dateActive {
    position: relative;
    background: var(--azure);
    color: var(--white);

    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-top: solid 10px var(--azure);
      border-right: solid 10px transparent;
      border-left: solid 10px transparent;
      width: 0;
      height: 0;
      content: '';
    }
  }

  /* stylelint-disable no-descending-specificity */
  .dateBlock + .dateBlock {
    margin-left: 15px;
  }

  .weekdayTitle {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
  }

  .weekdayTitle,
  .monthTitle {
    margin-bottom: 8px;
  }

  .monthTitle,
  .dayNumber {
    font-size: 15px;
  }

  .dayNumber {
    margin: 0;
    padding: 0;
    font-size: 36px;
    font-weight: bold;
  }

  .faContainerLeft,
  .faContainerRight {
    display: flex;
    align-items: center;
    /* stylelint-disable declaration-property-value-disallowed-list */
    border: none;
    background: transparent;

  }

  .faContainerLeft {
    padding: 0 15px 0 0;
  }

  .faContainerRight {
    padding: 0 0 0 15px;
  }

  button:focus {
    outline: 0;
  }

  .datePast {
    color: var(--even-darker-grey);
  }

  @media (min-width: $size-breakpoint-desktop) {
    .dateBlock {
      padding: 10px 35px;
    }
  }
}

@keyframes slideInLeft {
  from {
    transform: translate(-30px, 0) scale(0.98);
    opacity: 0;
  }

  to {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translate(30px, 0) scale(0.98);
    opacity: 0;
  }

  to {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

.animSlideInLeft {
  animation-name: slideInLeft;
  animation-duration: 0.8s;
}

.animSlideInRight {
  animation-name: slideInRight;
  animation-duration: 0.8s;
}

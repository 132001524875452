.inProgress {
  margin: 0;
  border-right-width: 1px;
  border-style: solid;
  background-color: var(--color-brand-secondary-50);
  padding: 0;
  height: 1px;
}

.completed {
  margin: 0;
  border-right-width: 1px;
  border-style: solid;
  background-color: var(--color-brand-success-50);
  padding: 0;
  height: 1px;
}

.onHold {
  margin: 0;
  border-right-width: 1px;
  border-style: solid;
  background-color: var(--color-brand-warning-50);
  padding: 0;
  height: 1px;
}

.cancelled {
  margin: 0;
  border-right-width: 1px;
  border-style: solid;
  background-color: var(--color-brand-danger-50);
  padding: 0;
  height: 1px;
}

.borderRight {
  border-right-width: 1px;
  border-style: solid;
  white-space: nowrap;
}

.alert {
  border-color: transparent;
  background: transparent;
  padding: 0.25rem;
}

// We should get rid of this in favor of upgrading to react router v6.
.linkButton {
  &:hover {
    text-decoration: none;
  }
}

.subtitle h2 {
  font-weight: normal;
}

.checklistMenu:nth-child(1) {
  border-top: 0;
}

.submissionAlert {
  background: transparent;
}

.defaultReadOnlyAlert {
  margin-top: 10px;
}

.textColorBrandBase {
  color: var(--color-brand-dark-base) !important;
}

.checklistMenuItem,
.checklistMenuItem:visited,
.checklistMenuItem:hover {
  display: flex;
  transition: background-color 0.2s ease, border 0.2s ease;
  border-left: var(--size-spacing-2xs) solid transparent;
  background-color: var(--white);
  text-decoration: none;


  &.selected {
    border-left: var(--size-spacing-2xs) solid var(--color-brand-grey-200);
    font-weight: var(--size-font-weight-bold);

    .checklistIcon {
      display: block;
    }

    .itemNumber {
      border-color: var(--color-brand-grey-200);
      background: var(--white);
    }
  }
}

.checklistMenuItemActive:hover {
  background-color: var(--color-brand-grey-50);
}

.checklistMenuItemInactive {
  color: var(--color-brand-grey-500);
}
